import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import displayContentActions from '../../features/displayContent/actions'
import { selectInDisplayContent } from '../../features/displayContent/selectors'
import { isTonometryContent } from '../../features/displayContent/utils'
import { selectExamId } from '../../features/exam/selectors'
import { pxToRem } from '../../libs/style'
import { TonometryContent } from '../../model/model'
import theme from '../../styleguide/theme'
import {
	EyeDataRow,
	EyeTableBox,
	FirstLine,
	ImagesBox,
	SecondLine,
	TableTitle,
	ThirdLine,
	TitleWithWidget,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import MediaBox, { EyeLong, EyeShort, Label } from './MediaBox'

const StyledEyeDataRow = styled(EyeDataRow)<{ hasBothEyes: boolean }>`
	justify-content: space-between;
	${props =>
		props.hasBothEyes
			? ''
			: `
	border: none;
	margin: 0;
`}
`

const StyledImagesBox = styled(ImagesBox)<{ hasBothImages: boolean }>`
	grid-template-columns: repeat(${props => (props.hasBothImages ? 2 : 1)}, 1fr);

	${props =>
		props.hasBothImages &&
		`
		padding: 0.5rem;
		column-gap: 1px;
	
		${EyeShort} {
			font-size:${theme.typography.fontSizes.M} ;
		}

		${EyeLong} {
			display: none;
		}

		${Label} {
			width: ${pxToRem(theme.spacing(5))}rem;
			height: ${pxToRem(theme.spacing(5))}rem;
		}

		& .vertical {
			margin: 0;
		}
	`}

	margin-top: ${pxToRem(theme.spacing(3))}rem;
	& > span {
		grid-column: span 2;
		color: ${theme.palette.primary.main};
	}
`

const TableBorderWrapper = styled.div<{ hasBothEyes: boolean }>`
	${props =>
		props.hasBothEyes
			? ''
			: `
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	border: solid 1px rgba(97, 164, 255, 0.4);
	height: 100%;
	display: flex;
    flex-direction: column;
    justify-content: center;
	`}
`

export const Tonometry: React.FC = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const displayContent = useSelector(selectInDisplayContent)

	const data = displayContent?.data as TonometryContent
	const examId = useSelector(selectExamId)

	useEffect(() => {
		if (examId)
			dispatch(displayContentActions.fetchInstrumentData(examId, 'NT'))
	}, [examId, dispatch])

	const hasNoImage =
		!data ||
		(isTonometryContent(data) &&
			!(data.content.OD?.path || data.content.OS?.path))
	const hasBothImages = !!(
		isTonometryContent(data) &&
		data.content.OD?.path &&
		data.content.OS?.path
	)

	return (
		<Wrapper>
			<TitleWithWidget>
				<TitleWrapper>
					<FirstLine>{t('content.clinicalData')}</FirstLine>
					<SecondLine>{t('content.tonometryTitle')}</SecondLine>
					<ThirdLine>{t('content.tonometryInfo')}</ThirdLine>
				</TitleWrapper>
				<EyeTableBox>
					<TableTitle>{t('content.tonometry')}</TableTitle>
					<TableBorderWrapper hasBothEyes={hasBothImages || hasNoImage}>
						{(isTonometryContent(data) && data.content.OD?.path) ||
						hasNoImage ? (
							<StyledEyeDataRow
								tonometry
								hasBothEyes={hasBothImages || hasNoImage}
								eye={'OD'}
								data={[
									{ label: 'content.iop1', value: data?.data?.R.iop1 || '-' },
									{ label: 'content.iop2', value: data?.data?.R.iop2 || '-' },
									{ label: 'content.iopc', value: data?.data?.R.iopc || '-' },
								]}
							></StyledEyeDataRow>
						) : null}
						{(isTonometryContent(data) && data.content.OS?.path) ||
						hasNoImage ? (
							<StyledEyeDataRow
								tonometry
								hasBothEyes={hasBothImages || hasNoImage}
								eye={'OS'}
								data={[
									{ label: 'content.iop1', value: data?.data?.L.iop1 || '-' },
									{ label: 'content.iop2', value: data?.data?.L.iop2 || '-' },
									{ label: 'content.iopc', value: data?.data?.L.iopc || '-' },
								]}
							></StyledEyeDataRow>
						) : null}
					</TableBorderWrapper>
				</EyeTableBox>
			</TitleWithWidget>
			{!hasNoImage && (
				<StyledImagesBox hasBothImages={hasBothImages} className="vertical">
					<TableTitle>{t('content.pachymetry')}</TableTitle>
					<MediaBox direction="vertical" media={data.content.OD} />
					<MediaBox direction="vertical" media={data.content.OS} />
				</StyledImagesBox>
			)}
		</Wrapper>
	)
}

export default Tonometry
